import { CBCompositionRunner } from "./CBCompositionRunner";
import { Brick, CBContext, CBEventInfo, DebugEvent, EmitLog, InsLeaves } from "./CBModels";
import { Clone, CloneLimit, DC_JOIN, ObjectDeepMerge, PrefabNameJoin } from "./CBUtil";
import { LeafResolver } from "./LeafResolver";
import { LinkMapper } from "./LinkMapper";
import { TemplateUtil } from "./TemplateUtil";

export abstract class CodeBrick {

    context: CBContext;
    public cid :string;
    public blueprint: Brick; 
    public brick_id: string;
    public ci_idx: number;
    
    public unresolved = {} as { [input:string] : string[] };

    public debug_ios = {} as { last_ins: { [input:string] : any }, last_outs: { [output:string]: any } };//For debugging only

    container_brick_id: string;
    idx: number;
    dc = "";
    parsed_ins?: InsLeaves; //For dynamic bricks we need to have one of these per brick (can't be shared)
    sources: string[] | undefined;
    run_list_name: string;

    loadings = 0;

    constructor(context: CBContext, cid:string, name: string, dc: string, idx: number, container_id: string, standalone_ins = "") {
    
        if(!cid) { // standalone
            this.context = context;
            this.cid = cid;
            this.blueprint = {
                name: name,
                type: "",
                ins: standalone_ins ? JSON.parse(standalone_ins) : {},
                cement: {},
                contains: [],
                targets: {}
            };
            this.brick_id = "";
            this.ci_idx = -1;
            this.container_brick_id = "";
            this.idx = 0;
            this.run_list_name = "";
            return;
        }

        this.context = context;
        this.cid = cid;
        this.blueprint = this.context.blueprints[this.cid][name];

        this.container_brick_id = container_id;
        this.idx = idx;

        this.dc = dc || "";
    
        this.context.ci_idx++;
        this.ci_idx = this.context.ci_idx;
    
        this.brick_id = "cb"+this.cid + '_' + this.blueprint.name;

        this.parsed_ins = this.blueprint.parsed_ins;
        if(this.dc && this.blueprint.parsed_ins) {
            this.parsed_ins = Clone(this.blueprint.parsed_ins);
        }

        //xc-use are in a different run list, because they should only run internals when they resolve. This run list is run from inside the xc-use cb_event.
        this.run_list_name = this.blueprint.nsid || "";

        if(dc) {
            
            let dcs = dc.split(DC_JOIN);
            this.context.dc_tree[this.cid][this.blueprint.name] = this.context.dc_tree[this.cid][this.blueprint.name] || {};
            let at_dc = this.context.dc_tree[this.cid][this.blueprint.name];
            for(let di = 1; di < dcs.length; di++) {
                let d = dcs[di];

                at_dc[DC_JOIN+d] = at_dc[DC_JOIN+d] || {};

                at_dc = at_dc[DC_JOIN+d];
            }

            for(let i = dc.length - 1; i >= 0; i--) {
                if(dc[i] == DC_JOIN) {
                    this.run_list_name = dc.substring(0, i);
                    break;
                }
            }

            // + " run_list_name "+this.run_list_name);

            //console.log(" dc_tree: "+JSON.stringify(this.context.dc_tree[this.cid]));
        }

        this.brick_id += dc || "";

        let run_list = this.context.composition_runners[this.cid].run_list;
        run_list[this.run_list_name] = run_list[this.run_list_name] || {};
        run_list[this.run_list_name][this.brick_id] = run_list[this.run_list_name][this.brick_id] || {};
        let has_in = false;
        for(let input in this.blueprint.ins) {
            if(input != "_cement") {       
                run_list[this.run_list_name][this.brick_id][input] = true;
                has_in = true;
            }
        }
        if(!has_in) {
            run_list[this.run_list_name][this.brick_id]["default"] = true;
        }
        
        //Delete this else buttons in grids can fire again when grid is recreated organization/bulkmonitor. Should only apply to dc bricks
        let base_nsid = this.blueprint.nsid || "";
        let s = this.blueprint.name.split(PrefabNameJoin);
        let bpn = s[s.length-1];
        if(this.context.composition_runners[this.cid].nsods[base_nsid] && this.context.composition_runners[this.cid].nsods[base_nsid][bpn]) {
            delete this.context.composition_runners[this.cid].nsods[base_nsid][bpn];
        }

        this.context.bricks[this.brick_id] = this;
    }

    IsInsLeafFiring(input: string, path: string) {
        if(this.parsed_ins && this.parsed_ins[input] && this.parsed_ins[input].leaves) {
            for(let leaf of this.parsed_ins[input].leaves) {
                if(leaf.path.join(".") == path) {
                    return leaf.firing;
                }
            }
        }
        return false;
    }

    destructor() {
        //delete this.context.bricks[this.brick_id];
    }

    init_cement() {
        let input = "_cement";

        let cements = {} as { [child_idx:number] : any };

        let base_nsid = this.blueprint.nsid || "";
        let base_ns = {} as any;
        if(this.cid) { //will be undefined for standalone       
            this.context.composition_runners[this.cid].nsods[base_nsid] = this.context.composition_runners[this.cid].nsods[base_nsid] || (this.context.composition_runners[this.cid].nsods["$"] != undefined ? Object.create(this.context.composition_runners[this.cid].nsods["$"]) : {});
            base_ns = this.context.composition_runners[this.cid].nsods[base_nsid];
        }

        if(this.blueprint.contains) {
            for(let c = 0; c < this.blueprint.contains.length; c++) {
                let child = this.blueprint.contains[c];
                let cement_res = child.cement;//ins[input] || {};
                if(child.parsed_ins && this.cid) {
                    //Get the cement
                    let in_leaves = child.parsed_ins[input];
                    
                    if(in_leaves) {

                        let unresolved = [] as string[];

                        // if(this.blueprint.name.indexOf("Subscribe") != -1) {
                        //      console.log("debug");
                        // }

                        cement_res = LeafResolver.Resolve(
                            in_leaves, 
                            child.ins[input], 
                            base_ns,//source_nsod,
                            this.context.composition_runners[this.cid].run_id,
                            "",
                            "@",
                            this.context.composition_runners[this.cid].compos?.system_options,
                            unresolved);

                        this.unresolved["_cement"] = unresolved;

                        // if(cement_res === undefined) {
                        //     console.log("CEMENT did not resolve "+this.brick_id+" cement " + JSON.stringify(child.ins[input]) + " unresolved "+JSON.stringify(unresolved));
                        // }
                    }              
                }
                cements[c] = cement_res;
            }
        }
        this.cb_initial_cement(cements); //needs to render even if no childs
    }

    async cb_emit(data:any, dc = undefined as string | undefined, no_event = false) {
        //console.log("cb_emit "+this.brick_id +" "+JSON.stringify(data));
        if(data) {

            if(this.cid == undefined) { //standalone
                for(let output in data) {
                    if((<any>window).so_callbacks && (<any>window).so_callbacks[this.brick_id] && (<any>window).so_callbacks[this.brick_id][output]) {
                        let callbacks = (<any>window).so_callbacks[this.brick_id][output] as ((value:any)=>undefined)[];
                        for(let cb of callbacks) {
                            cb(data[output]);
                        }
                    }
                }
                return;
            }

            if(!this.blueprint.targets) {
                return;
            }

            this.add_status("");
   
            this.context.composition_runners[this.cid].run_id = this.context.composition_runners[this.cid].seq + 1;

            let emit = null as any;
            if(this.context.composition_runners[this.cid].debugging_enabled) {
                emit = {
                    emitter: this.brick_id,
                    emit_data: data,
                    events: []
                };
                this.context.debug_log.emits.push(emit);
            
                //console.log("cb_emit "+this.brick_id +" "+JSON.stringify(data));
                if(data) {
                    for(let output in data) {
                        this.context.last_emit_data[this.cid][this.blueprint.name] = this.context.last_emit_data[this.cid][this.blueprint.name] || {};
                        this.context.last_emit_data[this.cid][this.blueprint.name].brick_type = this.blueprint.type;
                        this.context.last_emit_data[this.cid][this.blueprint.name].outputs = this.context.last_emit_data[this.cid][this.blueprint.name].outputs || {};
                        this.context.last_emit_data[this.cid][this.blueprint.name].outputs[output] = this.context.last_emit_data[this.cid][this.blueprint.name].outputs[output] || {}
                        this.context.last_emit_data[this.cid][this.blueprint.name].outputs[output].data = CloneLimit(data[output], 3, 20);
                    }
                }
            }
            let info = {
                source_brick_id: this.brick_id,
                source: this.blueprint.name,
                dc: dc || this.dc,
                no_event: no_event
            } as CBEventInfo;
            await this.send_data(data, info, emit);

            await this.context.composition_runners[this.cid].run_deferred_emits();

            if(this.context.composition_runners[this.cid].debugging_enabled) {
                //save last emit data
                //let all_last_emit_data = (<any>window).codebricks_context.last_emit_data[this.cid];
                let debug_log = this.context.debug_log;
                if(this.context.composition_runners[this.cid].save_debug_log_callback) {
                    ObjectDeepMerge(debug_log.last_emit_data, this.context.last_emit_data["_1"]);
                    this.context.last_emit_data["_1"] = debug_log.last_emit_data;

                    this.context.composition_runners[this.cid].RunDebugLogCallback();
                }
            }
        }
    }

    cb_emit_deferred(data:any, dc = undefined as string | undefined) {
        this.context.composition_runners[this.cid].deferred_emits[this.brick_id] = { data : data, dc: dc };
    }
                                                                                        //source_data is here just for deug loggin purposes
    //async run(input: string, source: string, source_output: string, source_idx: number, source_data: any, debuglog: EmitLog | null, source_brick_id: string) {
    async run(input: string, info: CBEventInfo, source_data: any, debuglog: EmitLog | null) {

        //console.log("run2 "+this.brick_id+" input "+input+ " source "+source+ " source_output " + source_output + " source_idx "+ source_idx);

        if(this.context.composition_runners[this.cid].seq > this.context.composition_runners[this.cid].run_id + CBCompositionRunner.MAX_ITERATIONS_PER_EMIT) {
            console.error("MAX_ITERATIONS_PER_EMIT "+CBCompositionRunner.MAX_ITERATIONS_PER_EMIT+ " hit. Stopping execution. ["+info.source_brick_id+"."+info.source_output+"->"+this.brick_id+"."+input+"]");
            return;
        }


        let run_list = this.context.composition_runners[this.cid].run_list;

        if(info.source == "") {
            if(!run_list[this.run_list_name][this.brick_id] || !run_list[this.run_list_name][this.brick_id][input]) {
                return; //Already ran in init phase
            }
        }
    
        if(run_list[this.run_list_name][this.brick_id]) {
            delete run_list[this.run_list_name][this.brick_id][input];
            if(Object.keys(run_list[this.run_list_name][this.brick_id]).length == 0) {
                delete run_list[this.run_list_name][this.brick_id];
            }
        } 
        
        this.blueprint.ins = this.blueprint.ins || {};
        let cfg = this.blueprint.ins[input];
        if(cfg === undefined) {
            cfg = {};
        }
        let source_nsod;
        let nsid = (this.blueprint.nsid || "") + (this.dc || "");
        
        //This does not work properly because the table has only one parsed in, so it remembers values incorrectly, even though the souce_nsod data is correct.
        // if(info.dc && info.dc.includes(DC_JOIN + this.blueprint.name + DC_JOIN)) {
        //     nsid += info.dc; //This is when a dc sends to a container, for example cd-table rowdata
        // }

        //console.log("run2 "+this.brick_id+" input "+input+" nsid " + nsid);
        let unresolved = [] as string[];

        if(this.parsed_ins) {
            let in_leaves = this.parsed_ins[input];
            if(in_leaves) {

                //console.log("nsid "+nsid + " "+this.blueprint.name + " ("+this.blueprint.type+") nsod " + this.blueprint.nsod);
                
                //this.context.composition_runners[this.cid].nsods[nsid] = this.context.composition_runners[this.cid].nsods[nsid] || (this.context.composition_runners[this.cid].nsods[""] != undefined ? Object.create(this.context.composition_runners[this.cid].nsods[""]) : {});
                this.context.composition_runners[this.cid].nsods[nsid] = this.context.composition_runners[this.cid].nsods[nsid] || (this.context.composition_runners[this.cid].nsods["$"] != undefined ? Object.create(this.context.composition_runners[this.cid].nsods["$"]) : {});
                source_nsod = this.context.composition_runners[this.cid].nsods[nsid];

                // if(this.blueprint.name.indexOf("Subscribe") != -1) {
                // // // // // // // // // if(this.brick_id == "cb_0_ch_ContractType-chart") {//attachmentuploader-GetAttachments") {
                //     console.log("debug "+this.blueprint.name + " source "+ info.source);
                // }

                cfg = LeafResolver.Resolve(
                    in_leaves, 
                    this.blueprint.ins[input], 
                    source_nsod,
                    this.context.composition_runners[this.cid].run_id,
                    info.source,
                    info.source_output,
                    this.context.composition_runners[this.cid].compos?.system_options,
                    unresolved,
                );

                // if(this.blueprint.name.indexOf("fetch_solutionobjects") != -1) {
                //     console.log("resolved "+(cfg===undefined?"NO":"YES")+" " +this.blueprint.name + " source "+ info.source);
                // }

                this.unresolved[input] = unresolved;
            }
        }

        

        let debug_event = undefined as any;

        if(debuglog) {

            //console.log("debug logging "+source+" -> "+this.brick_id +" debuglog.emitter "+debuglog.emitter);

            debug_event = {
                source: info.source,
                output: info.source_output,   
                dest_name: this.blueprint.name,
                input: input, 
                resolved: cfg !== undefined,
                source_brick_id: debuglog.emitter == "INIT" ? "" : info.source_brick_id, //debuglog.emitter,//source_brick_id,
                source_data: source_data,
                dest: this.brick_id,          
                cfg: cfg
            } as DebugEvent;

            // if(!info.loop_list) {
            //     info.loop_list = [debug_event];
            // }
            // else {
            //     for(let d = info.loop_list.length - 1; d >= 0; d--) {
            //         let event = info.loop_list[d];
            //         if(event.source_brick_id && event.source_brick_id == debug_event.source_brick_id && event.dest == debug_event.dest && event.input == debug_event.input) {
            //             console.error("LOOP: " + event.source_brick_id +"["+d+"] of " + info.loop_list.length + " == " + debug_event.source_brick_id);
            //             for(let e = d; e < info.loop_list.length; e++) {
            //                 console.error(info.loop_list[e].source_brick_id+"."+info.loop_list[e].output+"->"+info.loop_list[e].dest+"."+info.loop_list[e].input);
            //             }
            //             console.error(debug_event.dest+"."+input);
            //             return;
            //         }
            //     }
            //     info.loop_list.push(debug_event);
            // }

            debuglog.events.push(debug_event);

            
        }

        if(info.no_event) {
            return;
        }
        
        if(cfg !== undefined) {

            // if(this.brick_id.indexOf(PrefabNameJoin) == -1 && info.source_brick_id && this.parsed_ins && this.parsed_ins[input]?.has_tags 
            //  //&& (!this.dc || this.dc only show where all dc numbers are 0
            // ) {
            // // //console.log("RESOLVED - "+this.brick_id+" input "+input+" source " + info.source_brick_id + " " + JSON.stringify(this.blueprint.ins[input]) +" -> "+JSON.stringify(cfg));
            //     console.log((info.source_brick_id || "INIT") + "->" + this.brick_id+" input "+input + " " + JSON.stringify(this.blueprint.ins[input]));// + " " + JSON.stringify(cfg));
            // }

            if(this.context.composition_runners[this.cid].debugging_enabled) {
                this.debug_ios = this.debug_ios || {};
                this.debug_ios.last_ins = this.debug_ios.last_ins || {};
                this.debug_ios.last_ins[input] = cfg;
            }

            if(input == "_cement") {
                let container = this.context.bricks[this.container_brick_id];
                let row_id = 0;
                if(this.dc) {
                    let s = this.dc.split(DC_JOIN);
                    row_id = Number(s[s.length-1]);
                }
                container.cb_update_cement(this.idx, cfg, row_id);

                return false;
            }
            else {

                // if(this.blueprint.name == "billperiod") {
                // //if(this.brick_id == "cb_0_attachmentuploader-GetAttachments") {
                //     console.log("debug");
                // }
    
                info.debug_event = debug_event;
                let emit_data = await this.cb_event(input, cfg, info);
                
                //console.log("emit_data " +this.brick_id +": "+JSON.stringify(emit_data));
                if(debug_event) {
                    debug_event.out_data = emit_data;
                }
                              
                info.source_brick_id = this.brick_id;

                if(this.blueprint.name == info.source) {
                    console.log("Potential infinite loop averted; "+this.blueprint.name+" received event from "+this.blueprint.name+", handled but not forwarded");
                    
                    return false; //prevent infinite loop. If the component is it's own source, then dont emit.
                }

                return await this.send_data(emit_data, info, debuglog);

            }          
        }
        else {
            
            //console.log("UNRESOLVED - "+this.brick_id+" input "+input +" source " + info.source  + " nsod "+JSON.stringify(source_nsod));
            
            // if(this.dc) {
            //     console.log("run2 unresolved - "+this.brick_id+" input "+input +" source " + source  + " cfg: " + JSON.stringify(this.blueprint.ins[input]) + " unresolved "+JSON.stringify(this.unresolved[input]));          
            // }

            return false;
        }         
    }
  
    async send_data(emit_object: { [output:string] : any }, info: CBEventInfo, debuglog: EmitLog | null) {
        let oname = this.blueprint.name_nso || this.blueprint.name;
        
        let oname_nests = oname.split(PrefabNameJoin);
        oname = oname_nests[oname_nests.length-1];
        
        if(emit_object && this.context.composition_runners[this.cid].debugging_enabled) {
            for(let output in emit_object) {
                if(output != "@debug_log") {
                    this.context.last_emit_data[this.cid][this.blueprint.name] = this.context.last_emit_data[this.cid][this.blueprint.name] || {};
                    this.context.last_emit_data[this.cid][this.blueprint.name].brick_type = this.blueprint.type;
                    this.context.last_emit_data[this.cid][this.blueprint.name].outputs = this.context.last_emit_data[this.cid][this.blueprint.name].outputs || {};
                    this.context.last_emit_data[this.cid][this.blueprint.name].outputs[output] = this.context.last_emit_data[this.cid][this.blueprint.name].outputs[output] || {};
                    this.context.last_emit_data[this.cid][this.blueprint.name].outputs[output].data = CloneLimit(emit_object[output], 3, 20);

  
                    this.debug_ios = this.debug_ios || {};
                    this.debug_ios.last_outs = this.debug_ios.last_outs || {};
                    this.debug_ios.last_outs[output] = this.context.last_emit_data[this.cid][this.blueprint.name].outputs[output].data;
                
                }
            }
        }

        let targets = this.blueprint.targets;
        if(this.blueprint.name_nso) {
            //we have to also adopt the targets of name_nso, we are in effect impersonating it, sending as it.
            targets = this.context.blueprints[this.cid][this.blueprint.name_nso].targets;
        }

        let emitted = false;

        if(targets) {

            let dc = info.dc || this.dc;

            let source_idx = 0;
            if(dc) { //TODO optimise, save source_idx
                let dcs = this.dc.split(DC_JOIN);
                source_idx = Number(dcs[dcs.length-1]);
            }

            let promises = [] as any[];
            for(let output in emit_object) {
                let output_targets = targets[output];

                let output_info = Clone(info);
                output_info.source_idx = source_idx;
                output_info.source_output = output;
                output_info.source = oname;

                for(let dest_name in output_targets) {
                    
                    //We also need to set it on the base dc so all components below us in the dc  tree can get the latest value sent
                    let target_blueprint = this.context.blueprints[this.cid][dest_name];
                    let target_blueprint_nsid = (target_blueprint.nsid || "");
                    this.context.composition_runners[this.cid].nsods[target_blueprint_nsid] = this.context.composition_runners[this.cid].nsods[target_blueprint_nsid] || (this.context.composition_runners[this.cid].nsods["$"] != undefined ? Object.create(this.context.composition_runners[this.cid].nsods["$"]) : {});
                    let target_blueprint_ns = this.context.composition_runners[this.cid].nsods[target_blueprint_nsid];

                    if(!target_blueprint_ns.hasOwnProperty(oname)) {
                        target_blueprint_ns[oname] = {};
                    }
                    target_blueprint_ns[oname][output] = {
                        d: emit_object[output],
                        r: this.context.composition_runners[this.cid].run_id,
                        s: ++this.context.composition_runners[this.cid].seq
                    };
                    

                    if(dc) {
                        //target ci might not exist yet.
                        //So get the target blueprint, get ns from that
                        //populate out values in there so they are available when it is created.
                        let target_blueprint = this.context.blueprints[this.cid][dest_name];
                        let target_blueprint_nsid = (target_blueprint.nsid || "") + (this.dc ||"");
                        
                        this.context.composition_runners[this.cid].nsods[target_blueprint_nsid] = this.context.composition_runners[this.cid].nsods[target_blueprint_nsid] || (this.context.composition_runners[this.cid].nsods["$"] != undefined ? Object.create(this.context.composition_runners[this.cid].nsods["$"]) : {});
                        
                        let target_blueprint_ns = this.context.composition_runners[this.cid].nsods[target_blueprint_nsid];

                        if(!target_blueprint_ns.hasOwnProperty(oname)) {
                            target_blueprint_ns[oname] = {};
                        }
                        target_blueprint_ns[oname][output] = {
                            d: emit_object[output],
                            r: this.context.composition_runners[this.cid].run_id,
                            s: this.context.composition_runners[this.cid].seq
                        };
                    }

                    let targets = this.context.composition_runners[this.cid].get_brick_ids(dest_name, dc);
                    for(let target of targets) {
                        let target_ci = this.context.bricks[target];
                        if(target_ci) {

                            
                            for(let dest_input in output_targets[dest_name]) {

                                //console.log(this.brick_id+" IS MAKING "+target+" RUN");
    
                                promises.push(target_ci.run(dest_input, output_info, emit_object[output], debuglog));
                            }
                        }
                        // else {
                        //     console.log(target+" NOT FOUND (source "+this.brick_id+")");
                        // }
                    }

                }               
            }
            await Promise.all(promises);
        }

        return emitted;
    }

    async flush_dynamic() {
        //console.log(this.brick_id+" flush_dynamic");
        if(this.context.bricks) {
            for(let b in this.context.bricks) {
                let brick = this.context.bricks[b];
                if(brick.dc) {
                    let s = brick.dc.split(DC_JOIN);
                    if(s[s.length-2] == this.blueprint.name) {
                        if(this.dc) {
                            if(!brick.dc.includes(this.dc)) {
                                continue;
                            }
                        }
                        //console.log(" " +this.brick_id+" DELETE "+b+" brick.dc "+brick.dc);
                        brick.cb_unload();
                        delete this.context.bricks[b];
                        //delete this.context.composition_runners[this.cid].nsods[brick.dc]; these don't change anything because brick is just recreated in nsods[""] on first row
                        //delete this.context.composition_runners[this.cid].nsods[""][brick.blueprint.name];
                    }
                }
            }
        }
    }

    async send_dynamic_initialisation_events(dc_data: any[]) : Promise<void> {

        if(!this.blueprint.contains || this.blueprint.contains.length == 0 || !dc_data) {
            return;
        }

        //we have to clear all nsods directly under this dc so they don't keep data between loads or pages
        for(let nsid in this.context.composition_runners[this.cid].nsods) {
            let s = nsid.split(DC_JOIN, 2);
            if(s[1] == this.blueprint.name) {
                delete this.context.composition_runners[this.cid].nsods[nsid];
            }
        }

        this.context.composition_runners[this.cid].run_id = this.context.composition_runners[this.cid].seq + 1;

        //console.log("send_dynamic_initialisation_events "+this.brick_id+" dc_data "+JSON.stringify(dc_data));

        let dc_root = (this.dc || "") + (DC_JOIN + this.blueprint.name);

        let oname = this.blueprint.name_nso || this.blueprint.name;     
        let oname_nests = oname.split(PrefabNameJoin);
        oname = oname_nests[oname_nests.length-1];
        //
        let targets = this.blueprint.targets;
        if(this.blueprint.name_nso) {
            //we have to also adopt the targets of name_nso, we are in effect impersonating it, sending as it.
            targets = this.context.blueprints[this.cid][this.blueprint.name_nso].targets;
        }

        let base_nsid = (this.blueprint.nsid || "") + (this.dc || "");
        this.context.composition_runners[this.cid].nsods[base_nsid] = this.context.composition_runners[this.cid].nsods[base_nsid] || {};
        let base_ns = this.context.composition_runners[this.cid].nsods[base_nsid];

        let cements_to_update = [] as { child_idx: number, cement: any; row_idx: number }[];

        let output = "@"       
        if(targets) {
            let output_targets = targets[output];
            for(let i = 0; i < dc_data.length; i++) {

                let child_dc = dc_root + DC_JOIN + i;
   
                for(let dest_name in output_targets) {
                    let target_blueprint = this.context.blueprints[this.cid][dest_name];
                    if(target_blueprint) {

                        let target_nsid = (target_blueprint.nsid || "") + child_dc;
                        this.context.composition_runners[this.cid].nsods[target_nsid] = this.context.composition_runners[this.cid].nsods[target_nsid] || Object.create(base_ns);
                        let target_ns = this.context.composition_runners[this.cid].nsods[target_nsid];
                                    
                        //We have to use hasOwnProperty, alse we will use and overwrite the parent's property
                        if(!target_ns.hasOwnProperty(oname)) {
                            if(base_ns[oname]) {
                                target_ns[oname] = Object.create(base_ns[oname]);
                            }
                            else {
                                target_ns[oname] = {};
                            }
                        }
                        if(!target_ns[oname].hasOwnProperty(output)) {
                            if(base_ns[oname] && base_ns[oname][output]) {
                                target_ns[oname][output] = Object.create(base_ns[oname][output]);
                            }
                        }
                        target_ns[oname][output] = { 
                            d: dc_data[i],
                            r: this.context.composition_runners[this.cid].run_id,
                            s: ++this.context.composition_runners[this.cid].seq
                        };  
                        
                        for(let input in output_targets[dest_name]) {
                            if(input == "_cement" && target_blueprint && target_blueprint.parsed_ins) {

                                let child_brick_id = "cb"+this.cid+"_"+target_blueprint.name + child_dc;
                                let cci = this.context.bricks[child_brick_id];
                                if(cci.container_brick_id == this.brick_id) {

                                    let in_leaves = target_blueprint.parsed_ins[input];
                            
                                    if(in_leaves) {

                                        let unresolved = [] as string[];

                                        let cement_res = LeafResolver.Resolve(
                                            in_leaves, 
                                            target_blueprint.ins[input], 
                                            target_ns,
                                            this.context.composition_runners[this.cid].run_id,
                                            "",
                                            "@",
                                            this.context.composition_runners[this.cid].compos?.system_options,
                                            unresolved);

                                        if(cement_res !== undefined) {
                                            cements_to_update.push({child_idx: cci.idx, cement: cement_res, row_idx: i});
                                        }
                                    }
                                }
                            }
                        }
                    }
                } 
                
            }
        }

        if(dc_data.length > 0 && this.context.composition_runners[this.cid].debugging_enabled) {
            this.context.last_emit_data[this.cid][this.blueprint.name] = this.context.last_emit_data[this.cid][this.blueprint.name] || {};
            this.context.last_emit_data[this.cid][this.blueprint.name].brick_type = this.blueprint.type;
            this.context.last_emit_data[this.cid][this.blueprint.name].outputs = this.context.last_emit_data[this.cid][this.blueprint.name].outputs || {};
            this.context.last_emit_data[this.cid][this.blueprint.name].outputs["@"] = this.context.last_emit_data[this.cid][this.blueprint.name].outputs["@"] || {};
            this.context.last_emit_data[this.cid][this.blueprint.name].outputs["@"].data = CloneLimit(dc_data[0], 3, 20);
            
            // this.context.last_emit_data[this.cid][this.blueprint.name] = this.context.last_emit_data[this.cid][this.blueprint.name] || {};
            // this.context.last_emit_data[this.cid][this.blueprint.name]["@"] = dc_data[0];
        }

        let emit = null as any;
        if(this.context.composition_runners[this.cid].debugging_enabled) {
            emit = {
                emitter: this.brick_id,
                emit_data: dc_data,
                events: []
            };
            this.context.debug_log.emits.push(emit);
        }

        let info = {
            source_idx: 0,
            source: "",
            source_output: "@",
            dc_data_len: dc_data.length
        } as CBEventInfo;
        await this.context.composition_runners[this.cid].run_init_passes(info, dc_root, emit);
        //await this.context.composition_runners[this.cid].run_init_passes("", "@", "", emit);

        for(let cto of cements_to_update) {
            this.cb_update_cement(cto.child_idx, cto.cement, cto.row_idx);
        }
    }

    emit_state(state: string) {

        //console.log("emit_state \""+state+"\" from "+this.brick_id);

        let targets = this.blueprint.targets;
        if(this.blueprint.name_nso) {
            //we have to also adopt the targets of name_nso, we are in effect impersonating it, sending as it.
            targets = this.context.blueprints[this.cid][this.blueprint.name_nso].targets;
        }
        if(targets) {
            for(let output in targets) {

                // if(output != "@") {
                //     continue;
                // }

                let dests = targets[output];
                    
                //console.log(" emit_state output "+output+" dests "+JSON.stringify(dests));

                for(let destid in dests) {

                    let is_unit_outs = false;
                    for(let input in dests[destid]) {
                        if(input == "unit_outs") {
                            is_unit_outs = true;
                            break;
                        }
                    }
                    if(is_unit_outs) {
                        continue;
                    }

                    //console.log("0 getTargets (emit_state) OF "+destid+", dc "+this.dc);

                    let dest_brick_ids = this.context.composition_runners[this.cid].get_brick_ids(destid, this.dc);
                    for(let dest_brick_id of dest_brick_ids) {
                        let ci = this.context.bricks[dest_brick_id];

                        if(ci) {

                            
                            // if(ci.blueprint.name == "ch_Total") {
                            //     console.log(" emit_state "+state+" from "+this.brick_id+" to "+dest_brick_id);
                            // }

                            ci.add_status(state);
                        }
                    }
                }
            }
        }
    }

    validate_sources(form_container: string) {
        let validates = true;
        if(!form_container) {

            if(this.blueprint.parsed_ins) { //&& this.sources === undefined) {
                this.sources = [];
                LinkMapper.GetSources(this.blueprint.parsed_ins, this.cid, this.blueprint.nsid || "", this.sources);
            }

            if(this.sources) {
                for(let source of this.sources) {
                    let source_brick_ids = this.context.composition_runners[this.cid].get_brick_ids(source, this.dc);
                    for(let source_brick_id of source_brick_ids) {
                        let ci = this.context.bricks[source_brick_id];

                        if(ci) {
                            if(!ci.cb_validate()) {
                                validates = false;
                            }
                        }
                    }
                }
            }
        }
        return validates;
    }

    form_value_submit() {
        //To be called by form elements that act as data inputs (for example inputs) when the enter key enter is pressed on them.
        //This will trigger validation checks on all the form elements and submit the form if success.
        if(this.blueprint.targets) {
            let out_dests = this.blueprint.targets; 
            for(let output in out_dests) {
                for(let dest in out_dests[output]) {
                    let dest_brick_ids = this.context.composition_runners[this.cid].get_brick_ids(dest, this.dc);
                    for(let dest_brick_id of dest_brick_ids) {
                        let ci = this.context.bricks[dest_brick_id];     
                        if(ci) {
                            if(ci.validate_sources("")) {
                                ci.cb_trigger_submit();
                            }
                        }
                    }
                }
            }
        }
    }

    add_status(status: string) {

        if(status == "loading") {
            if(this.loadings <= 0) {
                this.cb_status("loading");
            }
            this.loadings++;
        }
        else if(status == "") {
            if(this.loadings <= 1) {
                this.cb_status("");
            }
            this.loadings--;
        }
    }

    cb_trigger_submit(): void {
        //Override this when needed
        //For being called by the system on for example a button when enter is pressed on an input connected to it.
        
    }

    cb_validate(): boolean {
        //Override this then needed.
        //The implementation should check if the input validates, 
        // if it does not, it should show the graphical indication that validation failed, 
        // and return false.
        return true;
    }

    abstract cb_event(input: string, cfg: any, info: CBEventInfo): Promise<any>;
    abstract cb_initial_cement(cements: { [child_idx: number]: any }) : void;
    abstract cb_update_cement(child_idx: number, cement: any, row_idx: number) : void;
    abstract cb_status(status:string) : any;
    abstract cb_snapshot() : any;

    async cb_unload() {}
    
}